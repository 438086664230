import { Controller } from "@hotwired/stimulus"
import Masonry from 'masonry-layout'

export default class extends Controller {

    static targets = ['grid'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;



        var msnry = new Masonry( this.gridTarget, {
            // options
            columnWidth: '.grid-sizer',
            itemSelector: '.grid-item',
            gutter: '.gutter-sizer',
            percentPosition: true,
            transitionDuration: '0.5s',
            stagger: 30
        });

        setTimeout(() => {
            msnry.layout()
        }, 100)

    }


}
