import { Controller } from "@hotwired/stimulus"
import { toggle } from "slide-element";

export default class extends Controller {

    static targets = ['more','loadmore'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;

    }

    loadmore(event) {

        toggle(this.moreTarget)
        this.loadmoreTarget.remove()

    }


}