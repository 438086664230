import { Controller } from "@hotwired/stimulus"
import { toggle } from "slide-element";

export default class extends Controller {

    static targets = ['subnavigationspacer', 'subnavigation','icon'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;

    }

    initialize() {
        super.initialize();

        document.addEventListener('click', (event) => {
           if(event.target.closest(".fixedheader") == null) {
               this.closeMenu()
           }
        });

    }

    closeMenu() {
        const elements = document.querySelectorAll('[data-navigationDesktop-target="subnavigation"]');
        const visibleElements = Array.from(elements).filter(element => {
            return window.getComputedStyle(element).display !== 'none';
        });

        if(visibleElements.length>0) {
            visibleElements.forEach((visibleElement) => {

                visibleElement.closest("li").classList.remove('active')
                visibleElement.classList.add("hidden")

                visibleElement.closest("li").querySelector('[data-navigationDesktop-target="icon"]').classList.remove('rotate-180')
                visibleElement.closest("li").querySelector('[data-navigationDesktop-target="icon"]').classList.remove('force-icon-gold')

            });
        }
    }

    toggle(event) {

        event.preventDefault()

        var cantoggle = true

        const elements = document.querySelectorAll('[data-navigationDesktop-target="subnavigation"]');
        const visibleElements = Array.from(elements).filter(element => {
            return window.getComputedStyle(element).display !== 'none';
        });

        if(visibleElements.length>0) {
            visibleElements.forEach((visibleElement) => {

                visibleElement.classList.add("hidden")
                visibleElement.closest("li").classList.remove('active')

                visibleElement.closest("li").querySelector('[data-navigationDesktop-target="icon"]').classList.remove('rotate-180')
                visibleElement.closest("li").querySelector('[data-navigationDesktop-target="icon"]').classList.remove('force-icon-gold')

            });

            if(visibleElements[0]==this.subnavigationTarget) {

                visibleElements[0].classList.add("hidden")
                visibleElements[0].closest("li").classList.remove('active')


                cantoggle = false
                visibleElements[0].closest("li").querySelector('[data-navigationDesktop-target="icon"]').classList.remove('rotate-180')
                visibleElements[0].closest("li").querySelector('[data-navigationDesktop-target="icon"]').classList.remove('force-icon-gold')
            }
        }


        if(cantoggle) {

            this.subnavigationTarget.classList.remove("hidden")
            this.subnavigationTarget.closest("li").classList.add('active')

            this.iconTarget.classList.add('rotate-180')
            this.iconTarget.classList.add('force-icon-gold')
        }

    }

    scroll() {
        this.closeMenu()
    }



}