import { Controller } from "@hotwired/stimulus"
import { toggle } from "slide-element";

export default class extends Controller {

    static targets = ['subnavigation','icon'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;

    }

    initialize() {
        super.initialize();

    }

    toggle(event) {

        event.preventDefault()

        var cantoggle = true

        const elements = document.querySelectorAll('[data-navigationMobileSubnavigation-target="subnavigation"]');
        const visibleElements = Array.from(elements).filter(element => {
            return window.getComputedStyle(element).display !== 'none';
        });

        if(visibleElements.length>0) {
            visibleElements.forEach((visibleElement) => {

                toggle(visibleElement)
                visibleElement.closest("li").classList.remove('active')

                visibleElement.closest("li").querySelector('[data-navigationMobileSubnavigation-target="icon"]').classList.remove('rotate-180')
                visibleElement.closest("li").querySelector('[data-navigationMobileSubnavigation-target="icon"]').classList.remove('force-icon-gold')

            });

            if(visibleElements[0]==this.subnavigationTarget) {

                toggle(visibleElements[0])
                visibleElements[0].closest("li").classList.remove('active')


                cantoggle = false
                visibleElements[0].closest("li").querySelector('[data-navigationMobileSubnavigation-target="icon"]').classList.remove('rotate-180')
                visibleElements[0].closest("li").querySelector('[data-navigationMobileSubnavigation-target="icon"]').classList.remove('force-icon-gold')
            }
        }


        if(cantoggle) {

            toggle(this.subnavigationTarget)

            this.subnavigationTarget.closest("li").classList.add('active')

            this.iconTarget.classList.add('rotate-180')
            this.iconTarget.classList.add('force-icon-gold')
        }

    }




}