import { Controller } from "@hotwired/stimulus"
import Flickity from 'flickity'
import 'flickity-fade'

export default class extends Controller {

    static targets = ['slider','indicator','outer','overlay'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;

        setTimeout(() => {
            this.outerTarget.classList.add("transition-opacity")
            setTimeout(() => {
                this.outerTarget.classList.remove("opacity-0")

                var flkty = new Flickity(  this.sliderTarget, {
                    // options
                    cellAlign: 'center',
                    initialIndex: 0,
                    wrapAround: true,
                    pageDots: true,
                    prevNextButtons: false,
                    draggable: false,
                    autoPlay: false,
                    fade: false,
                    on: {
                        ready: () => {

                            setTimeout(() => {
                                const slides = this.outerTarget.querySelectorAll(".slide")
                                slides.forEach((slide) => {
                                    slide.classList.remove("transition-none")
                                });
                            }, 100);

                            this.indicatorTarget.classList.add("slider-running")
                            this.indicatorTarget.querySelector(".slider-indicator").addEventListener('transitionend', this.onTransitionEnd.bind(this));
                            const dots = this.outerTarget.querySelectorAll(".flickity-page-dot")
                            dots.forEach((dot) => {
                                dot.addEventListener('click', this.onDotClick.bind(this));
                            });
                            this.overlayTarget.classList.add("transition-opacity")
                            this.overlayTarget.classList.add("ease-out-expo")
                            this.overlayTarget.classList.add("duration-[1000ms]")
                            setTimeout(() => {
                                this.overlayTarget.classList.add("!opacity-100")
                            }, 1000)
                        }
                    }
                });

            }, 10);
        }, 100);





    }

    onTransitionEnd(event) {
        if (event.target === this.indicatorTarget.querySelector(".slider-indicator")) {
            var flkty = Flickity.data(  this.sliderTarget );
            flkty.next()
            this.indicatorTarget.classList.add("slider-reset")
            this.indicatorTarget.classList.remove("slider-running")
            setTimeout(() => {
                this.indicatorTarget.classList.remove("slider-reset")
                this.indicatorTarget.classList.add("slider-running")
            },10)
        }
    }

    onDotClick(event) {
        const clickedDot = event.currentTarget;
        var flkty = Flickity.data(  this.sliderTarget );
        flkty.stopPlayer()
        this.indicatorTarget.classList.add("slider-reset")
        this.indicatorTarget.classList.remove("slider-running")
    }


}
