import { Controller } from "@hotwired/stimulus"
import { toggle } from "slide-element";

export default class extends Controller {

    static targets = ['navigation', 'holder', 'iconburger','iconburgerclose'];

    static values = {
        'state': false
    }


    connect() {

        if (this.initValue) return;
        this.initValue = true;

    }

    initialize() {
        super.initialize();

    }


    toggle() {
        if(!this.stateValue) {

            document.querySelector("html").classList.add("disable-scroll-smooth")

            setTimeout(() => {
                document.body.style.top = `-${window.scrollY}px`
                document.body.style.position = 'fixed'
                document.body.style.overflowY = 'scroll'
                document.body.style.width = '100%'

                this.navigationTarget.classList.remove("hidden")

                setTimeout(() => {
                    this.navigationTarget.classList.add("max-h-[100vh]")
                    setTimeout(() => {
                        this.holderTarget.classList.add("opacity-100")
                    }, 200)
                }, 10)

                this.iconburgerTarget.classList.add("hidden")
                this.iconburgercloseTarget.classList.remove("hidden")
            }, 10)

        } else {
            this.iconburgerTarget.classList.remove("hidden")
            this.iconburgercloseTarget.classList.add("hidden")

            this.holderTarget.classList.remove("opacity-100")
            setTimeout(() => {
                this.navigationTarget.classList.remove("max-h-[100vh]")
                setTimeout(() => {
                    this.navigationTarget.classList.add("hidden")
                },800);


                const scrollY = document.body.style.top;
                document.body.style.position = '';
                document.body.style.top = '';
                document.body.style.overflowY = ''
                document.body.style.width = ''

                window.scrollTo(0, parseInt(scrollY || '0') * -1);

                setTimeout(() => {
                    document.querySelector("html").classList.remove("disable-scroll-smooth")
                }, 100);

            }, 300)

        }
        this.stateValue = !this.stateValue
    }


}