import { Controller } from "@hotwired/stimulus"
import Flickity from 'flickity'

export default class extends Controller {

    static targets = ['slider'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;

        var flkty = new Flickity(  this.sliderTarget, {
            // options
            cellAlign: 'center',
            initialIndex: 0,
            wrapAround: true,
            pageDots: true,
            arrowShape: 'M71.867,100L78,93.867L34.134,50L78,6.134L71.867,0L24.933,46.933L22,50l2.933,3.067L71.867,100z'
        });

    }


}
