import { Controller } from "@hotwired/stimulus"
import { toggle } from "slide-element";
import AOS from "aos";

export default class extends Controller {

    static targets = ['filter','filtertoggle','members','filterlabel'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;

        var members = this.membersTarget.querySelectorAll(".team-member");
        members.forEach((member) => {
            member.classList.add("visible")
        });
        setTimeout(() => {
            members.forEach((member) => {
                member.classList.add("initialized")
            });
        }, 100)

        setTimeout(() => {
            var members = this.membersTarget.querySelectorAll(".team-member");
            members.forEach((member, index) => {
                member.removeAttribute('data-aos');
            });
        }, 1000)

        document.addEventListener('click', this.closeFilter.bind(this));

    }

    filter(event) {
        if(event.target.hasAttribute('data-filter')) {
            const filter_id = event.target.getAttribute('data-filter');

            this.filterlabelTarget.innerText = event.target.innerText



            var members = this.membersTarget.querySelectorAll(".team-member:not(.hidden)");
            members.forEach((member, index) => {
                member.classList.remove('visible');
            });

            setTimeout(() => {
                members = this.membersTarget.querySelectorAll(".team-member");
                members.forEach((member) => {
                    if(filter_id=="") {
                        member.classList.remove("hidden")
                    } else {
                        if (member.classList.contains(filter_id)) {
                            member.classList.remove("hidden")
                        } else {
                            member.classList.add("hidden")
                        }
                    }
                });
                members = this.membersTarget.querySelectorAll(".team-member:not(.hidden)");
                members.forEach((member, index) => {
                    setTimeout(() => {
                        member.classList.add('visible');
                    }, index * 100);
                });
                AOS.init({
                    once: true
                });
            }, 600)
        }
        this.filterTarget.classList.add("hidden")
    }

    togglefilter(event) {
        this.filterTarget.classList.toggle("hidden")
    }

    closeFilter(event) {
        if (!this.filterTarget.classList.contains("hidden") && event.target != this.filtertoggleTarget && !event.target.hasAttribute('data-filter')) {
            this.filterTarget.classList.add("hidden");
        }
    }

}