import { Controller } from "@hotwired/stimulus"
import { toggle } from "slide-element";

export default class extends Controller {

    static targets = ['content','plus','close'];


    connect() {

        if (this.initValue) return;
        this.initValue = true;

    }

    toggle(event) {

        toggle(this.contentTarget)
        setTimeout(() => {
            this.contentTarget.closest(".espresso").scrollIntoView({ behavior: 'smooth' })
        }, 500)



        if(!this.plusTarget.classList.contains("hidden")) {
            this.plusTarget.classList.add("hidden")
            this.closeTarget.classList.remove("hidden")
        } else {
            this.plusTarget.classList.remove("hidden")
            this.closeTarget.classList.add("hidden")
        }

    }


}